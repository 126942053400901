import React from "react";
import { SEO, MarkdownContent, VideoPlayer, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  getComponentConfigurationData,
} from "../utils/utils";

export default class SpecialsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.theutopiasalon.com/specials/"}
        />
        <Grid
          className={"custom-page-specials custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "437c4b0d-81cf-46eb-b279-336a2accde65",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={2}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"justified"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <VideoPlayer
                  className={"video-block"}
                  style={{}}
                  url={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "26a73a67-1b61-4cf2-a6b8-274c34c69adc",
                    dataPath: "data.video.url",
                  })}
                  videoId={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "26a73a67-1b61-4cf2-a6b8-274c34c69adc",
                    dataPath: "data.video.videoId",
                  })}
                  source={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "26a73a67-1b61-4cf2-a6b8-274c34c69adc",
                    dataPath: "data.video.source",
                  })}
                  placeholder={getComponentConfigurationData({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "VideoPlayer",
                    componentIdentifier: "26a73a67-1b61-4cf2-a6b8-274c34c69adc",
                    dataPath: "data.video.placeholder",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "6f6ac2b3-4431-45f6-84c3-2823a9985b3f",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26187 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
        data {
          video {
            url
            source
            videoId
            placeholder
          }
        }
      }
    }
  }
`;
